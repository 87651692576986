import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    function groupBy(data: Array<any>, groupByColumns, groupByAggregates): Array<any> {
        const columns = groupByColumns.map(item => item["name"])
        const groupByAggregatesColumns = groupByAggregates.map(item => item["name"]);

        const result = data.reduce((result, currentValue) => {
            const keyObject = Object.keys(currentValue)
                .filter(key => columns.includes(key))
                .reduce((obj, key) => {
                    obj[key] = currentValue[key];
                    return obj;
                }, {});

            const key = Object.values(keyObject).join("-");
            //Handle everything "after" the groupByColumn key has been created here
            if (result.hasOwnProperty(key)) {
                result[key] = result[key];

                //Add aggregate properties
                if (groupByAggregates.length > 0) {

                    groupByAggregates.forEach((aggregation) => {
                        switch (aggregation.groupByAggregate) {
                            case "COUNT":
                                if (result[key][columns[0]] == currentValue[columns[0]]) {
                                    result[key][aggregation["alias"]] += 1
                                }
                                break;
                            case "SUM":
                                result[key][aggregation.alias] += currentValue[aggregation.name];

                        }
                    });
                }
            }
            //Handle everything "before" the groupByColumn key has been created here
            else {
                const newGroupByItem = Object.fromEntries(columns.map(column => [column, currentValue[column]]));
                result[key] = newGroupByItem
                groupByAggregates.forEach((aggregation) => {
                    result[key][columns[0]] = currentValue[columns[0]];
                    switch (aggregation.groupByAggregate) {
                        case "COUNT":
                            result[key][aggregation.alias] = 1;
                            break;
                        case "SUM":
                            result[key][aggregation.alias] = currentValue[aggregation.name];
                            break;
                    }
                });
            }
            return result;
        }, {});

        const grouped = Object.values(result);

        const cleanedUp = grouped.map(item => {
            groupByAggregatesColumns.forEach(column => delete item[column])
            return item;
        });

        return cleanedUp;
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.utilities.dataObject.groupBy.ts')>({ groupBy });
})();
